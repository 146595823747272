<template>
  <v-container 
  :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
  fluid>
  <v-card-actions>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/areas" />
      </v-col>
    </v-row>
    </v-card-actions>
    <areas-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import areas from "@/api/areas";

export default {
  components: {
    AreasCampos: () => import("./AreasCampos"),
  },

  data() {
    return {
      tab: null,
      carregando: false,
      validacaoFormulario: {},
    };
  },

  methods: {
    async salvar(area) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        await areas.inserir(area);

        this.$router.push("/areas", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
